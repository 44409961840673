ul {
    margin: 0;
    padding:0;
}
.navbar {
    padding-top: 25px;
    padding-bottom: 25px;
}
.sidebar{
    li {
        padding: 5px;
    }
    a { 
        text-transform:capitalize;
    }
} 
.post-title {
    align-items: center;
}
.post-actions {
    width:150px;
}
.post-date {
    width:150px;
}
table {
    a {
        color: #687281;
        font-size: 0.85rem;
        font-weight: 400;
    line-height: 1.5;
    }
    
}
#dashboard {
    margin:50px 0;
    .col-lg-10 {
        background:#FFF;
        //outline: 1px solid #eaeaea;
    }
    
    h1 {
        margin-top:25px;
        margin-bottom:50px;
    }
    ul.sidebar {
        border:1px solid rgba($color: #6f7495, $alpha: .25);
        padding:15px 35px 15px 10px;
        border-radius:  5px;
        li {
            white-space: nowrap;
        }
        li.active-nav-item {
            color: #000;
    font-weight: bold;
        }
    }
     margin-bottom: 100px;
}
.dashboard {
    padding:50px 0;
   // background-color: rgba($color: #6f7495, $alpha: .25);
    ul.sidebar {
        background:#fff;
        border:1px solid rgba($color: #6f7495, $alpha: .25);
        padding:15px 35px 15px 10px;
        li {
            white-space: nowrap;
        }
        li.active-nav-item {
            color: #000;
    font-weight: bold;
        }
    }
}
.logout {
   margin:15px 15px 0;
}
.additional-info{
   margin:25px 15px;
}
.navbar {
    background-color: #FFF !important;
    color:#000!important;
    
    a {
        text-transform: capitalize;
        white-space: nowrap;
        color:#000!important;
    }
    .navbar-brand {
        font-size:2rem;
        color:#000;
    }
    .navbar-nav {
        padding-top:10px;
        color:#000;
    }
}
.navigation {
    height:322px;
    overflow: hidden;
}
header {
    position:absolute;
    width:100%;
    top:0;
    background-color: #FFF;
    color:#000;
    background-image: url(/public/images/header-music2.jpg);
    background-repeat: no-repeat;
    background-position: 90% 0%;
}
header.home {
    background-image: url(/public/images/header-home2.jpg);
}
header.events {
    background-image: url(/public/images/header-events2.jpg);
}
header.music {
    background-image: url(/public/images/header-music2.jpg);
}
header.rentalcatalogue {
    background-image: url(/public/images/header-rentalcatalogue2.jpg);
    background-position: 90% -118%;
}
header.order {
    background-image: url(/public/images/header-order2.jpg);
}
header.biography {
    background-image: url(/public/images/header-biography2.jpg);
}
header.agenda {
    background-image: url(/public/images/header-agenda2.jpg);
}



.navigation ul {
    display:table;
    margin: 15px auto 15px;
    li {
        display: inline;
        margin-right:25px;
        text-transform: uppercase;
     a {
         color:#FFF;
         
     }
    }
}

    .headline {
        text-align:left;
        margin: 113px 0 113px 100px;
        color:#000;
        font: 900 18px/20px 'Montserrat', sans-serif;
   
        span {
            font: 900 46px/48px 'Montserrat', sans-serif; 
            margin-bottom:15px;
            display: block;
            color:#a90000
        }
    }
    .events {
        .headline {
            text-align:left;
            margin: 113px 0 113px 100px;
        }
    }
    .agenda {
        .headline {
            text-align:left;
            margin: 113px 0 113px 100px;
        }
    }
    .music {
        .headline {
            text-align:left;
            margin: 113px 0 113px 100px;
        }
    }
    .biography {
        .headline {
            text-align:left;
            margin: 113px 0 113px 100px;
        }
    }
    .rentalcatalogue {
        .headline {
            text-align:left;
            margin: 113px 0 113px 100px;
        }
    }
    .order {
        .headline {
            text-align:left;
            margin: 113px 0 113px 100px;
        }
    }
    .nav-row {
         background-color:rgba($color:  #a90000, $alpha: 1);
         
    }
    header {
        ul {
            
            margin: 15px auto 15px;
            li {
                display: inline-block;
                margin-right:25px;
                text-transform: uppercase;
             a {
                 color:#FFF;
                 text-decoration: none;
             }
             a:hover {
                 color:#000;
                 text-decoration: none;
             }
            }
            
        }
        .nav-row .container .col-lg-12 {
            display: flex;
            justify-content: center;
        }
    }
    header.home {
        li.home{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }
    header.events {
        li.events{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }
    header.agenda {
        li.agenda{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }
    header.music {
        li.music{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }

    header.rentalcatalogue {
        li.rental{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }
    header.order {
        li.order{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }
    header.biography {
        li.biography{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }
    header.gallery {
        li.gallery{
            a {
                color:#000;
                font-weight:bold;
            }
            
        }
    }

    @media screen and (min-width: 320px) and (max-width: 778px){/*Large*/
        header.home {
            background-image: url(/public/images/header-home2.jpg);
            background-repeat: no-repeat;
            background-position: 30% 0%;
            background-repeat: no-repeat;
        }
        .headline {
            color:#FFF;
            -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
        span {
            color:#a90000!important;
        }
    }
   
    }
    @media screen and (min-width: 1200px) and (max-width: 1599px){/*XLarge*/
        header.home {
            background-image: url(/public/images/header-home2.jpg);
            background-repeat: no-repeat;
            background-position: 90% 0%;
            background-repeat: no-repeat;
        }
    }
    @media screen and (min-width: 1600px) {/*XXLarge*/
        header.home,
        header.biography,
        header.order,
        header.rentalcatalogue,
        header.music,
        header.agenda,
        header.events
         {
            background-position: 66% 0%;
            background-repeat: no-repeat;
        }
    }
    .form-row{
        --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex
;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
    margin-bottom: 10px;
    }
    .form-group {
        padding: 10px;
    }
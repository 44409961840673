html,body {
    height: 100%;
} 
body {
    background: #fff;
    font-family: "Work Sans",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6f7495;
    text-align: left; 
    height: 100%;
}
a,
a:hover,
.card-link {
    color:#9a0000;
}
.card-image img:hover,
.card-link:hover {
    cursor: pointer;
}
ul {
    list-style:none; 
    margin:0;
    padding:0;
}
h1,h3,h3,h4,h5 {
    font: 900 18px/20px 'Montserrat', sans-serif;
}
.h2, h2 {
    font-size: 2rem;
}
.form-control{
    border-radius: 0;
} 
#home,
#sign-in {
    height: 100%;
}

#sign-in,
#sign-up{
   margin-top:50px;
   margin-bottom:50px;
    min-height: 100%;
}
#images,#product,#music{
   // margin-bottom:50px;
    background:#FFF;
    padding:25px 35px;
    border: 1px solid rgba(111, 116, 149, 0.25);
    border-bottom: none;
}
#images {
    
    .caption {
      div {
        width:100%;
      }
    }
  }
table {
    th {
        font-size:.9rem;
    }
    .btn {
        padding:.175rem .475em;
        font-size:.8rem;
        text-transform: capitalize;
    }
    td {
        font-size: .8rem;
    }
    .post-title{
        width:33%;
    }
}
#post-image,
#post-image-main,
#post-image-front,
#post-image-back,
#gallery-image, 
#mp3-file{
    display:none;
}
.action-button,.caption{
    display: flex;
    align-items: center;
    justify-content: center;
}
.post-image {
    text-align: "center"; 
    img {
         width:100px;
    }
}  

.navbar-dark .navbar-nav .nav-link {
    text-transform: capitalize;
    white-space: nowrap;
    color:#000!important;
}

.jdm-button {
    background-color: #9a0000;
    border-color: #9a0000;
}
.article {
   h1 {
    color: #9a0000 !important;
   }
}
.RentalCatalogue {
    margin-top:390px;
    th {
        color: #000;
        vertical-align: middle!important;
        font: 900 14px/16px 'Montserrat', sans-serif;
        text-align: center;
    }
    td.catalogue-composer  {
        text-transform: capitalize;
    }
    td.catalogue-title,
    td.catalogue-number,
    td.catalogue-composer a {
        color:#9a0000; 
        text-transform: none;
    }
    td.catalogue-title:hover,
    td.catalogue-number:hover,
    td.catalogue-composer a :hover {
        text-decoration: underline;
        cursor: pointer;
    }
    thead tr:hover,
    tr:hover{
        text-decoration: none;
        cursor: auto;
        color:#000
    }
}
.order {
    
    .location {
        font: 400 14px/16px 'Work Sans', sans-serif;
        padding:15px;
        background: rgba(234, 234, 234, 0.3);
        margin-bottom:25px; 
        li {
            margin-bottom:5px;
        }
        .country {
            color:#000;
            font-weight: bold;
        }
    }
}
section.order {
    margin-top:295px;
}
.pagination {
    .page-link {
        color: #6f7495!important;
    }
    .active-page {
        .page-link {
            color:#fff!important;
        }  
        .page-link,.page-item:hover {
            background:#9a0000;
            
        }  
        
        a {
            color:#fff;
        }
    }
}
.cds {
    clear:both;
}
@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import 'navbar';
@import 'dashboard';   
@import 'agenda';
@import 'about';
@import 'news';
@import 'events'; 
@import 'footer';
@import 'article';
@import 'music';
@import 'contactus';   
@import 'gallery';
.sidebar {
    ul {
        margin-bottom: 100px;
    }
    li {
       padding:0!important; 
       a {
           font-size: .8rem;
           color: #6f7495;
       }
       a:hover {
           color:#9a0000
       }
    }
}
.btn-primary:hover {
    background:#000;
}

.maintence-box {
    padding:50px;
    border: 5px solid #aeaeae;
    margin:25% auto;
    text-align:center;
} 
select.on{
    border-color: #28a745;
    background-color: #28a745;
    color:#FFF;
}
.card-body {
    padding-top:0;
}
section.order {
    margin-top:350px;
}
@media screen and (min-width:320px) and (max-width:374px){
    .nav-row {
        margin-top: -36px;
    }
    .headline{
        margin-left:15px; 
        span {
            font: 900 40px/42px 'Montserrat', sans-serif;
        }
    }
    section.news {
        margin-top:365px;
        padding-top:0;
    }
    header {
        .home {
        background-position: 58% 0%!important;
        }
     }
}
@media screen and (min-width:375px) and (max-width:410px){
    .news-items, .concert-info, footer {
        width:375px;
    }
    .headline{
        margin-left:20px; 
        span {
            font: 900 45px/48px 'Montserrat', sans-serif;
        }
    }
    header {
        .home {
        background-position: 58% 0%;
        }
     }
}
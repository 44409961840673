@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
html, body {
  height: 100%;
}

body {
  background: #fff;
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6f7495;
  text-align: left;
  height: 100%;
}

a,
a:hover,
.card-link {
  color: #9a0000;
}

.card-image img:hover,
.card-link:hover {
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1, h3, h3, h4, h5 {
  font: 900 18px/20px "Montserrat", sans-serif;
}

.h2, h2 {
  font-size: 2rem;
}

.form-control {
  border-radius: 0;
}

#home,
#sign-in {
  height: 100%;
}

#sign-in,
#sign-up {
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 100%;
}

#images, #product, #music {
  background: #FFF;
  padding: 25px 35px;
  border: 1px solid rgba(111, 116, 149, 0.25);
  border-bottom: none;
}

#images .caption div {
  width: 100%;
}

table th {
  font-size: 0.9rem;
}
table .btn {
  padding: 0.175rem 0.475em;
  font-size: 0.8rem;
  text-transform: capitalize;
}
table td {
  font-size: 0.8rem;
}
table .post-title {
  width: 33%;
}

#post-image,
#post-image-main,
#post-image-front,
#post-image-back,
#gallery-image,
#mp3-file {
  display: none;
}

.action-button, .caption {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-image {
  text-align: "center";
}
.post-image img {
  width: 100px;
}

.navbar-dark .navbar-nav .nav-link {
  text-transform: capitalize;
  white-space: nowrap;
  color: #000 !important;
}

.jdm-button {
  background-color: #9a0000;
  border-color: #9a0000;
}

.article h1 {
  color: #9a0000 !important;
}

.RentalCatalogue {
  margin-top: 390px;
}
.RentalCatalogue th {
  color: #000;
  vertical-align: middle !important;
  font: 900 14px/16px "Montserrat", sans-serif;
  text-align: center;
}
.RentalCatalogue td.catalogue-composer {
  text-transform: capitalize;
}
.RentalCatalogue td.catalogue-title,
.RentalCatalogue td.catalogue-number,
.RentalCatalogue td.catalogue-composer a {
  color: #9a0000;
  text-transform: none;
}
.RentalCatalogue td.catalogue-title:hover,
.RentalCatalogue td.catalogue-number:hover,
.RentalCatalogue td.catalogue-composer a :hover {
  text-decoration: underline;
  cursor: pointer;
}
.RentalCatalogue thead tr:hover,
.RentalCatalogue tr:hover {
  text-decoration: none;
  cursor: auto;
  color: #000;
}

.order .location {
  font: 400 14px/16px "Work Sans", sans-serif;
  padding: 15px;
  background: rgba(234, 234, 234, 0.3);
  margin-bottom: 25px;
}
.order .location li {
  margin-bottom: 5px;
}
.order .location .country {
  color: #000;
  font-weight: bold;
}

section.order {
  margin-top: 295px;
}

.pagination .page-link {
  color: #6f7495 !important;
}
.pagination .active-page .page-link {
  color: #fff !important;
}
.pagination .active-page .page-link, .pagination .active-page .page-item:hover {
  background: #9a0000;
}
.pagination .active-page a {
  color: #fff;
}

.cds {
  clear: both;
}

.navbar {
  background-color: #FFF !important;
  color: #000 !important;
}
.navbar a {
  text-transform: capitalize;
  white-space: nowrap;
  color: #000 !important;
}
.navbar .navbar-brand {
  font-size: 2rem;
  color: #000;
}
.navbar .navbar-nav {
  padding-top: 10px;
  color: #000;
}

.navigation {
  height: 322px;
  overflow: hidden;
}

header {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: #FFF;
  color: #000;
  background-image: url(/public/images/header-music2.jpg);
  background-repeat: no-repeat;
  background-position: 90% 0%;
}

header.home {
  background-image: url(/public/images/header-home2.jpg);
}

header.events {
  background-image: url(/public/images/header-events2.jpg);
}

header.music {
  background-image: url(/public/images/header-music2.jpg);
}

header.rentalcatalogue {
  background-image: url(/public/images/header-rentalcatalogue2.jpg);
  background-position: 90% -118%;
}

header.order {
  background-image: url(/public/images/header-order2.jpg);
}

header.biography {
  background-image: url(/public/images/header-biography2.jpg);
}

header.agenda {
  background-image: url(/public/images/header-agenda2.jpg);
}

.navigation ul {
  display: table;
  margin: 15px auto 15px;
}
.navigation ul li {
  display: inline;
  margin-right: 25px;
  text-transform: uppercase;
}
.navigation ul li a {
  color: #FFF;
}

.headline {
  text-align: left;
  margin: 113px 0 113px 100px;
  color: #000;
  font: 900 18px/20px "Montserrat", sans-serif;
}
.headline span {
  font: 900 46px/48px "Montserrat", sans-serif;
  margin-bottom: 15px;
  display: block;
  color: #a90000;
}

.events .headline {
  text-align: left;
  margin: 113px 0 113px 100px;
}

.agenda .headline {
  text-align: left;
  margin: 113px 0 113px 100px;
}

.music .headline {
  text-align: left;
  margin: 113px 0 113px 100px;
}

.biography .headline {
  text-align: left;
  margin: 113px 0 113px 100px;
}

.rentalcatalogue .headline {
  text-align: left;
  margin: 113px 0 113px 100px;
}

.order .headline {
  text-align: left;
  margin: 113px 0 113px 100px;
}

.nav-row {
  background-color: #a90000;
}

header ul {
  margin: 15px auto 15px;
}
header ul li {
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
}
header ul li a {
  color: #FFF;
  text-decoration: none;
}
header ul li a:hover {
  color: #000;
  text-decoration: none;
}
header .nav-row .container .col-lg-12 {
  display: flex;
  justify-content: center;
}

header.home li.home a {
  color: #000;
  font-weight: bold;
}

header.events li.events a {
  color: #000;
  font-weight: bold;
}

header.agenda li.agenda a {
  color: #000;
  font-weight: bold;
}

header.music li.music a {
  color: #000;
  font-weight: bold;
}

header.rentalcatalogue li.rental a {
  color: #000;
  font-weight: bold;
}

header.order li.order a {
  color: #000;
  font-weight: bold;
}

header.biography li.biography a {
  color: #000;
  font-weight: bold;
}

header.gallery li.gallery a {
  color: #000;
  font-weight: bold;
}

@media screen and (min-width: 320px) and (max-width: 778px) { /*Large*/
  header.home {
    background-image: url(/public/images/header-home2.jpg);
    background-repeat: no-repeat;
    background-position: 30% 0%;
    background-repeat: no-repeat;
  }
  .headline {
    color: #FFF;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }
  .headline span {
    color: #a90000 !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) { /*XLarge*/
  header.home {
    background-image: url(/public/images/header-home2.jpg);
    background-repeat: no-repeat;
    background-position: 90% 0%;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 1600px) { /*XXLarge*/
  header.home,
  header.biography,
  header.order,
  header.rentalcatalogue,
  header.music,
  header.agenda,
  header.events {
    background-position: 66% 0%;
    background-repeat: no-repeat;
  }
}
.form-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-bottom: 10px;
}

.form-group {
  padding: 10px;
}

ul {
  margin: 0;
  padding: 0;
}

.navbar {
  padding-top: 25px;
  padding-bottom: 25px;
}

.sidebar li {
  padding: 5px;
}
.sidebar a {
  text-transform: capitalize;
}

.post-title {
  align-items: center;
}

.post-actions {
  width: 150px;
}

.post-date {
  width: 150px;
}

table a {
  color: #687281;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
}

#dashboard {
  margin: 50px 0;
  margin-bottom: 100px;
}
#dashboard .col-lg-10 {
  background: #FFF;
}
#dashboard h1 {
  margin-top: 25px;
  margin-bottom: 50px;
}
#dashboard ul.sidebar {
  border: 1px solid rgba(111, 116, 149, 0.25);
  padding: 15px 35px 15px 10px;
  border-radius: 5px;
}
#dashboard ul.sidebar li {
  white-space: nowrap;
}
#dashboard ul.sidebar li.active-nav-item {
  color: #000;
  font-weight: bold;
}

.dashboard {
  padding: 50px 0;
}
.dashboard ul.sidebar {
  background: #fff;
  border: 1px solid rgba(111, 116, 149, 0.25);
  padding: 15px 35px 15px 10px;
}
.dashboard ul.sidebar li {
  white-space: nowrap;
}
.dashboard ul.sidebar li.active-nav-item {
  color: #000;
  font-weight: bold;
}

.logout {
  margin: 15px 15px 0;
}

.additional-info {
  margin: 25px 15px;
}

section.agenda {
  border-top: 1px solid #eaeaea;
  padding-top: 25px;
}

section.agenda,
section.agenda-full {
  margin-top: 320px;
  padding-top: 50px;
  padding-bottom: 100px;
  font-size: 0.75rem;
  text-align: left;
  color: #000;
}
section.agenda h3, section.agenda h3 a,
section.agenda-full h3,
section.agenda-full h3 a {
  font: 900 14px/16px "Roboto", sans-serif;
  color: #9a0000 !important;
  margin: 0 0 50px 0;
}
section.agenda .agenda-card,
section.agenda-full .agenda-card {
  margin: 15px 0;
  background: rgba(234, 234, 234, 0.3);
  min-height: 215px;
}
section.agenda .conductor,
section.agenda-full .conductor {
  font-style: italic;
  font-size: 0.9rem;
}
section.agenda .orchestra,
section.agenda-full .orchestra {
  font-size: 1.2rem;
}
section.agenda .title,
section.agenda-full .title {
  font-size: 0.75rem;
}
.agenda-list {
  font-size: 0.8rem;
}
.agenda-list .title {
  font: 900 14px/16px "Montserrat", sans-serif;
}
.agenda-list .time, .agenda-list .momth {
  color: #000;
  font: 900 14px/16px "Montserrat", sans-serif;
}
.agenda-list .date {
  padding: 10px 0 5px 0;
  text-align: center;
  background: rgba(174, 174, 162, 0.3);
  outline: 1px solid #fff;
  color: #9a0000;
}
.agenda-list .date .day {
  font: 900 38px/40px "Montserrat", sans-serif;
  line-height: 2rem;
  color: #000;
  font-weight: lighter;
}
.agenda-list .date .year {
  color: #000;
  font-size: 1rem;
}
.agenda-list .agenda-info {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(174, 174, 162, 0.3);
}

.archive ul {
  display: table;
  margin-bottom: 35px;
}
.archive li {
  display: table-cell;
  padding: 0 25px 0 0;
  color: #9a0000;
  font: 900 24px/26px "Montserrat", sans-serif;
}
.archive li.active {
  color: #000;
}
.archive li:hover {
  cursor: pointer;
}

section.about {
  border-top: 1px solid #eaeaea;
  padding: 100px 0;
}

section.about,
section.about-full {
  background: #FFF;
  color: #000;
}
section.about h3,
section.about-full h3 {
  font-size: 2rem !important;
  color: #9a0000 !important;
  margin-bottom: 25px;
}
section.about button,
section.about-full button {
  margin: 35px 0 50px;
}

section.about-full {
  margin-top: 415px;
}
section.about-full h5 {
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-top: 25px;
  color: #000;
}
section.about-full ul li {
  display: inline;
  margin-right: 10px;
}

section.news {
  margin-top: 400px;
  background: #FFF;
  padding: 25px 0 0;
  min-height: calc(100vh - 660px);
}
section.news h2 {
  color: #000 !important;
  text-align: center;
  margin: 50px 0;
  font-size: 2rem !important;
  line-height: 1.3;
  letter-spacing: -0.01em;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
section.news h3 {
  text-align: center !important;
  margin: 50px 0;
  font-size: 1rem !important;
  line-height: 1.3;
  letter-spacing: -0.01em;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.news-card {
  width: 100%;
  background: #FFF;
  position: relative;
  margin-bottom: 25px;
}
.news-card .card-text {
  min-height: 47px;
  max-height: 47px;
  font-size: 0.8rem;
}
.news-card .card-body {
  position: absolute;
  top: 0;
  left: 150px;
  padding: 0 15px 15px !important;
  background: rgba(234, 234, 234, 0.3);
  border-top: none;
  min-height: 162px;
}

.card-date {
  color: #495057;
}

.card-title {
  font-size: 1.5rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.card-link {
  margin-top: 15px;
}

.concert-info {
  background: rgba(73, 80, 87, 0.1);
}
.concert-info h4 {
  font: 400 24px/26px "Work Sans", sans-serif;
  color: #000;
  margin-bottom: 50px;
}
.concert-info button {
  margin-top: 50px;
}
.concert-info .container {
  padding-top: 100px;
  margin-top: 50px;
  padding-bottom: 100px;
}
.concert-info .container .form-control {
  background-color: #fff;
  border-color: rgba(73, 80, 87, 0.1);
  color: #495057;
}

section.events {
  margin-top: 330px;
  font-size: 1rem;
}
section.events h3 {
  margin-bottom: 25px;
  color: #fe5c56;
  font-size: 1.5rem;
}

footer {
  background-color: #FFF;
  color: #495057;
}
footer h5 {
  margin-bottom: 25px;
}
footer .social-media ul li {
  margin: 0;
  padding: 0;
  line-height: 1rem;
  float: left;
  margin-right: 5px;
}
footer ul {
  margin: 0 0 50px;
  padding: 0;
}
footer .fa-facebook {
  background: #3B5998;
  color: white;
}
footer .fa-twitter {
  background: #55ACEE;
  color: white;
}
footer .fa-linkedin {
  background: #007bb5;
  color: white;
}
footer .fa {
  padding: 5px;
  font-size: 20px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}
footer .fa:hover {
  opacity: 0.7;
}
footer .footer-copyright {
  color: #495057;
  font-size: 0.8rem;
}
footer .footer-copyright a {
  color: #495057;
}
footer .footer-copyright .fa {
  margin-right: 10px;
  color: #FFF;
}
footer .footer-copyright ul {
  margin: 0;
}
footer .footer-copyright ul li {
  margin-right: 10px;
  font-size: 0.8rem;
  white-space: nowrap;
  text-align: left;
}
footer .footer-copyright .bottom-row {
  padding-top: 25px;
  border-top: 1px solid rgba(73, 80, 87, 0.3);
}
footer .footer-copyright .form-control {
  background-color: #fff;
  border-color: #495057;
  color: #495057;
}
footer .nav-link {
  padding: 0;
}
footer button {
  margin-bottom: 50px;
}

section.article {
  color: #000;
}
section.article h1 {
  color: #9a0000;
}
section.article .lead-image {
  margin-bottom: 50px;
  max-height: auto;
  width: 100%;
  background-position: 10% 50%;
  overflow: hidden;
}

section.music {
  margin: 375px 0 50px;
}
section.music h3 {
  font: 900 18px/20px "Montserrat", sans-serif;
  margin-bottom: 25px;
}
section.music li {
  font: 400 14px/16px "Work Sans", sans-serif;
  margin-bottom: 5px;
}
section.music li span {
  color: #a90000;
}
section.music li span:hover {
  text-decoration: underline;
  cursor: pointer;
}
section.music ul.categories {
  padding: 0;
  margin-bottom: 25px;
  background: rgba(234, 234, 234, 0.3);
  padding: 15px;
}
section.music ul.categories li {
  display: inline;
  margin-right: 10px;
  font: 400 12.5px/14px "Montserrat", sans-serif;
  padding-right: 10px;
  border-right: 1px solid #000;
  color: #a90000;
}
section.music ul.categories li:hover {
  cursor: pointer;
  text-decoration: underline;
}
section.music ul.categories li:nth-child(8) {
  margin-right: 0;
  border: none;
}
section.music ul.categories li.active {
  color: #000;
  font-weight: bold;
}
section.music .soren {
  margin-bottom: 25px;
}
section.music .cds li {
  float: left;
  width: 100px;
  min-height: 180px;
  margin-right: 15px;
  position: relative;
  margin-bottom: 35px;
}
section.music .cds li img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
}
section.music .cds li .title {
  text-align: center;
  font: 400 12px/13px "Work Sans", sans-serif;
  color: #a90000;
}
section.music .cds li span {
  display: block;
  height: 100px;
  margin-bottom: 10px;
}

.music-profile {
  margin-top: 390px;
  margin-bottom: 50px;
}
.music-profile h1 {
  font: 400 32px/34px "Work Sans", sans-serif;
  color: #000;
}
.music-profile h3 {
  font: 400 22px/24px "Work Sans", sans-serif;
  color: #fe5c56;
}
.music-profile h5 {
  font: 400 16px/18px "Work Sans", sans-serif;
}

.composer {
  text-transform: capitalize;
}
.composer a {
  color: #a90000;
}
.composer a span {
  text-transform: lowercase !important;
}

.details {
  background: #eaeaea;
  font: 400 14px/16px "Work Sans", sans-serif;
  padding: 15px;
  margin: 25px 0 25px;
  max-width: 350px;
}
.details span {
  color: #000;
  text-transform: capitalize;
}

section.contact-us {
  padding: 100px 0;
  background: #aeaeae;
  color: #FFF;
}

section.photo-gallery {
  margin-top: 375px;
}
section.photo-gallery .alice-carousel li.alice-carousel__stage-item {
  height: 600px;
}
section.photo-gallery .alice-carousel__prev-btn {
  text-align: center !important;
}
section.photo-gallery .gallery-images table ul li {
  margin-bottom: 15px;
}

.gallery-images table ul li {
  margin-bottom: 15px;
}
.gallery-images table ul li span {
  font-weight: bold;
}

#images #date {
  display: none;
}

.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.gallery-image {
  margin-bottom: 35px;
}

.alice-carousel__dots {
  margin-top: 0;
}

.thumbnails {
  display: table;
  margin: 0 auto;
}
.thumbnails li {
  display: table-cell;
  padding-right: 15px;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: visible !important;
  display: flex;
  justify-content: center;
}

.thumb {
  display: inline-block;
  margin-right: 15px;
  margin-top: 15px;
}
.thumb img:hover {
  cursor: pointer;
}

.galleryContainer {
  min-height: 200px;
  max-height: 600px;
  overflow: hidden;
  position: relative;
}
.galleryContainer ul {
  display: flex;
}
.galleryContainer .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}
.galleryContainer .slide img {
  border: 1px solid #000;
}

.legend {
  background: rgb(0, 0, 0);
  padding: 10px;
  color: #FFF;
  position: absolute;
  bottom: 50%;
  width: 80%;
  left: 10%;
  font: 400 12px/14px "Montserrat", sans-serif;
  border-radius: 5px;
}

.vail {
  position: absolute;
  z-index: 999;
  background: #FFF;
  width: 100%;
  height: 100%;
  display: none;
}

.fade-in {
  animation: fadeIn ease 30s;
  -webkit-animation: fadeIn ease 30s;
  -moz-animation: fadeIn ease 30s;
  -o-animation: fadeIn ease 30s;
  -ms-animation: fadeIn ease 30s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut ease 8s;
  -webkit-animation: fadeOut ease 8s;
  -moz-animation: fadeOut ease 8s;
  -o-animation: fadeOut ease 8s;
  -ms-animation: fadeOut ease 8s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media screen and (min-width: 320px) and (max-width: 778px) { /*Large*/
  .galleryContainer {
    max-height: 294px;
  }
}
@media screen and (min-width: 779px) and (max-width: 991px) { /*Large*/
  .galleryContainer {
    max-height: 441px;
  }
}
.sidebar ul {
  margin-bottom: 100px;
}
.sidebar li {
  padding: 0 !important;
}
.sidebar li a {
  font-size: 0.8rem;
  color: #6f7495;
}
.sidebar li a:hover {
  color: #9a0000;
}

.btn-primary:hover {
  background: #000;
}

.maintence-box {
  padding: 50px;
  border: 5px solid #aeaeae;
  margin: 25% auto;
  text-align: center;
}

select.on {
  border-color: #28a745;
  background-color: #28a745;
  color: #FFF;
}

.card-body {
  padding-top: 0;
}

section.order {
  margin-top: 350px;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .nav-row {
    margin-top: -36px;
  }
  .headline {
    margin-left: 15px;
  }
  .headline span {
    font: 900 40px/42px "Montserrat", sans-serif;
  }
  section.news {
    margin-top: 365px;
    padding-top: 0;
  }
  header .home {
    background-position: 58% 0% !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 410px) {
  .news-items, .concert-info, footer {
    width: 375px;
  }
  .headline {
    margin-left: 20px;
  }
  .headline span {
    font: 900 45px/48px "Montserrat", sans-serif;
  }
  header .home {
    background-position: 58% 0%;
  }
}/*# sourceMappingURL=main.css.map */
section.about{
    border-top:1px solid #eaeaea; 
    padding:100px 0;
}
section.about,
section.about-full {
  
    background:#FFF;
    color:#000;
    h3 {
        font-size: 2rem !important;
        color: #9a0000 !important;
        margin-bottom:25px;
    }
    button {
        margin:35px 0 50px;
    }
    
}
section.about-full {
    margin-top:415px;
    h5 {
        font-size:.9rem;
        text-transform: uppercase;
        margin-top:25px;
        color:#000;
    }
    ul li {
        display:inline;
        margin-right:10px;
    }
    
}
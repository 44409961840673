section.agenda{
    
     border-top:1px solid #eaeaea;
     padding-top:25px;
}
section.agenda,
section.agenda-full {
    margin-top:320px;
    padding-top:50px;
    padding-bottom:100px;
   // margin-bottom:50px;
    font-size:.75rem; 
    text-align: left;
   
   
    color:#000;
    h3,h3 a {
        font: 900 14px/16px 'Roboto', sans-serif;
        color:#9a0000!important;
        margin:0 0 50px 0;
    }
    .agenda-card{
        margin:15px 0;
        //border:5px solid #fe5c56;
        background: rgba(234, 234, 234, 0.3);
        min-height:215px;
    } 
    .conductor {
            font-style: italic;
            font-size:.9rem;
        }
    .orchestra {
        font-size:1.2rem;
       
    }
    .title {
        font-size:.75rem; 
    }
   
    .date {
       
    }
    
}
.agenda-list {
    
    font-size:.8rem; 
    .title {
        font: 900 14px/16px 'Montserrat', sans-serif;
    }
    .time,.momth {
        color: #000;
        font: 900 14px/16px 'Montserrat', sans-serif;
    }
    .date { 
        padding:10px 0 5px 0; 
        text-align:center;
        background: rgba(174, 174, 162, 0.3);
        outline:1px solid #fff;
         color: #9a0000;
        .day {
            font: 900 38px/40px 'Montserrat', sans-serif;
            line-height: 2rem;
            color:#000;
            font-weight: lighter;
        }
        .year {
            color:#000;
            font-size:1rem; 
        }
    }
   .agenda-info{
       padding-top:10px;
       padding-bottom:10px;
       border-bottom:1px solid rgba(174, 174, 162, 0.3);
   }
   // margin-bottom:15px;
    
}
.archive {
    ul {
        display: table;
        margin-bottom:35px;
    }
    li {
        display: table-cell;
        padding:0 25px 0 0;
        color: #9a0000;
        font: 900 24px/26px 'Montserrat', sans-serif;
    }
    li.active {
        color: #000;
    }
    li:hover {
        cursor: pointer;
    }
}
section.article {
    color:#000;
    h1 {
        color:#9a0000;
    }
    .lead-image {
        margin-bottom:50px;
        max-height:auto;
        width:100%;
        background-position:10% 50%;
        overflow:hidden
    }
}
section.news {
  margin-top:400px;
  background:#FFF;
  padding: 25px 0 0;
  min-height: calc(100vh - 660px);
  
 h2 {
   color:#000!important;
  text-align: center;
  margin: 50px 0;
  font-size: 2rem !important;
  line-height: 1.3;
  letter-spacing: -0.01em;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
 }
 h3 {
  text-align: center !important;
  margin: 50px 0;
  font-size: 1rem !important;
  line-height: 1.3;
  letter-spacing: -0.01em;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
 }
} 
.news-card {
  width:100%;
  background:#FFF;
  position:relative;
 // border-radius:10px;

  margin-bottom:25px;
  
  
  .card-text {
    min-height: 47px;
    max-height:47px;
    font-size:.8rem;
  }
 
  .card-body  {
    position: absolute;
    top:0;
    left:150px;
    padding:0 15px 15px!important;
    background: rgba(234, 234, 234, 0.3);
    border-top:none;
    min-height:162px;
      
  }  
}  
 .card-date {
  color:#495057;
  }

.card-title {
  font-size: 1.5rem;
  color:#000;
  font-weight: 700;
letter-spacing: .02em;
text-transform: uppercase;
margin-bottom:25px;
}
.card-link {
  margin-top:15px;
}
.concert-info {
 background:rgba(73, 80, 87, 0.1);
 h4 {
  font: 400 24px/26px 'Work Sans', sans-serif;
  color:#000;
   margin-bottom:50px;
 }
 button {
   margin-top:50px;
 }
  .container {
    // border-top:1px solid rgba(73, 80, 87, 0.1);
    // border-bottom:1px solid rgba(73, 80, 87, 0.1);
    padding-top:100px;
    margin-top:50px;
    padding-bottom:100px;
    .form-control {
      background-color: #fff;
      border-color: rgba(73, 80, 87, 0.1);
      color:#495057;
  }
  }
}
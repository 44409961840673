section.music {
    margin:375px 0 50px;
    h3 {
        font: 900 18px/20px 'Montserrat', sans-serif;
        margin-bottom:25px;
    }
    li {
        
        font: 400 14px/16px 'Work Sans', sans-serif;
        margin-bottom:5px;
        span {
            color:#a90000;
        }
        span:hover {
            text-decoration:underline;
            cursor: pointer;
        }
    }
   ul.categories {
       padding:0;
       margin-bottom: 25px;
       background: rgba(234, 234, 234, 0.3);
       padding:15px;
        li {
            display:inline;
            margin-right:10px;
            font: 400 12.5px/14px 'Montserrat', sans-serif;
            padding-right:10px;
            border-right:1px solid #000;
           color:#a90000;
        }
        li:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        li:nth-child(8) {  
            margin-right:0;
            border:none;
        }
        li.active {
            color:#000;
            font-weight:bold;
        }
   }
   .soren {
       margin-bottom:25px;
   }
   .cds {
      
       li {
       float:left;
        width:100px;
        min-height:180px;
        margin-right:15px;
        position:relative;
        margin-bottom:35px;
        img {
            width: 100px;
            height:100px;
            position:absolute;
            top:0;
        }
        .title {
            text-align:center;
            font: 400 12px/13px 'Work Sans', sans-serif;
            color:#a90000;
        }
       }
       li span {
           display:block;
           height:100px;
           margin-bottom:10px;
       }
   }
}
.music-profile {
   margin-top:390px;
    margin-bottom:50px;
    h1 {
        font: 400 32px/34px 'Work Sans', sans-serif;
        color:#000;
    }
    h3{
        font: 400 22px/24px 'Work Sans', sans-serif;
        color:#fe5c56;
    }
    h5{
        font: 400 16px/18px 'Work Sans', sans-serif;
        //color:#fe5c56;
    }
     
    }
    .composer{
        text-transform: capitalize;
        a {
        color:#a90000;
        span {
            text-transform: lowercase !important;
        }
    }
}
.details { 
    background:#eaeaea; 
    font: 400 14px/16px 'Work Sans', sans-serif;
    padding:15px;
    margin: 25px 0 25px;
    max-width:350px;
    span{
        color:#000;
        text-transform:capitalize;
    }
}
footer {  
    background-color: #FFF;
    color:#495057;
    h5 {
        margin-bottom:25px;
    }
    
    .social-media ul li {
        margin:0;
        padding:0;
        line-height:1rem;
        float:left;
        margin-right:5px;
    }
    ul {
        margin:0 0 50px;
        padding:0; 
    }
    .fa-facebook {
        background: #3B5998;
        color: white;
      }
      .fa-twitter {
        background: #55ACEE;
        color: white;
      }
      .fa-linkedin {
        background: #007bb5;
        color: white;
      }
    .fa {
        padding: 5px;
  font-size: 20px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
      }
      .fa:hover {
        opacity: 0.7;
    }
    .footer-copyright {
        color:#495057;
        font-size:.8rem;
        a {
            color:#495057;
        }
        .fa {
            margin-right: 10px;
            color:#FFF;
        }
        ul {
       // display:flex;
        //justify-content: space-between;
        margin:0;
        li {
            margin-right:10px;
            font-size:.8rem;
            white-space: nowrap;
            text-align: left;
            }
        }
        .bottom-row {
            padding-top:25px;
            border-top:1px solid rgba(73, 80, 87, 0.3);
        }
        .form-control {
            background-color: #fff;
            border-color: #495057;
            color:#495057;
        }
    }
    .nav-link {
        padding:0;
    }
    button {
        margin-bottom:50px;
    }
}